document.addEventListener("DOMContentLoaded", function () {
    console.log("Main.js Version 1.0.15");

    const modals = document.getElementsByClassName('modal');
    for (let i = 0; i < modals.length; i++) {
        const modal = modals[i];

        const modalButton = document.getElementById(`modalButton-${i}`);
        const modalCloseIcon = document.getElementById(`modalCloseIcon-${i}`);
        const modalCloseButton = document.getElementById(`modalCloseButton-${i}`);

        if (modalButton) {
            modalButton.onclick = () => {
                modal.style.display = 'block';
            };
        }
        if (modalCloseIcon) {
            modalCloseIcon.onclick = () => {
                modal.style.display = 'none';
            };
        }
        if (modalCloseButton) {
            modalCloseButton.onclick = () => {
                modal.style.display = 'none';
            };
        }
    }

    window.onclick = function(event) {
        if (event.target.classList.contains('modal')) {
            event.target.style.display = 'none';
        }
    };

    // Set footer year dynamically
    const yearElement = document.getElementById("year");
    if (yearElement) {
        console.log("Set Date: " + new Date().getFullYear());
        yearElement.textContent = new Date().getFullYear();
    }

    const links = document.getElementsByClassName('link');
    for (let i = 0; i < links.length; i++) {
        links[i].onclick = () => {
            const navCheck = document.getElementById('nav-check');
            if (navCheck) {
                navCheck.checked = false;
            }
        };
    }
});